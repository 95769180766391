/* eslint-disable max-len */
import React, {FC} from 'react';
import Layout from '../layouts/Primary';
import SubHeader from '../components/SubHeader';

const Page: FC = () =>
	<Layout>
		<SubHeader
			title="Privacy Policy"
		/>
		<div className="ex-basic-2">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="text-container">
							<h3>Private Data We Receive And Collect</h3>
							<h6>Updated July 2021</h6>
							<p>BeamFire automatically collects and receives certain information from your
							computer or
							mobile device, including the activities you perform on our Website, the Platforms, and the
							Applications, the type of hardware and software you are using (for example, your operating
							system or browser), and information obtained from cookies. For example, each time you visit
							the Website or otherwise use the Services, we automatically collect your IP address, browser
							and device type, access times, the web page from which you came, the regions from which you
							navigate the web page, and the web page(s) you access (as applicable).</p>
							<p>When you first register for a BeamFire account, and when you use the Services, we
							collect some Personal Information about you such as:
							</p>
							<div className="row">
								<div className="col-md-6">
									<ul className="list-unstyled li-space-lg indent">
										<li className="media">
											<i className="fas fa-square" />
											<div className="media-body">The geographic area where you use your computer and
											mobile devices</div>
										</li>
										<li className="media">
											<i className="fas fa-square" />
											<div className="media-body">Your full name, username, and email address and other
											contact details</div>
										</li>
										<li className="media">
											<i className="fas fa-square" />
											<div className="media-body">A unique BeamFire user ID (an alphanumeric
											string) which is
											assigned to you upon registration</div>
										</li>
										<li className="media">
											<i className="fas fa-square" />
											<div className="media-body">Your IP Address and, when applicable, timestamp related
											to your consent and confirmation of consent
											</div>
										</li>

									</ul>
								</div>

								<div className="col-md-6">
									<ul className="list-unstyled li-space-lg indent">
										<li className="media">
											<i className="fas fa-square" />
											<div className="media-body">Your billing address and any necessary other information
											to complete any financial transaction, and when making purchases through the
											Services, our third-party payment system PayPal may also collect your credit
											card or information or any
											other sensitive data that you consider</div>
										</li>
										<li className="media">
											<i className="fas fa-square" />
											<div className="media-body">Other information submitted by you or your
											organizational representatives via various methods and forms
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div className="text-container">
							<h3>How We Use BeamFire Data</h3>
							<p>BeamFire uses visitors&apos; data for the following general purposes
							and for
							other specific ones that are important:</p>
							<ol className="li-space-lg">
								<li>To identify you when you login to your account so we can authenticate and secure you
								for the entire session and duration</li>
								<li>To enable us to operate the Services including site configurations and settings</li>
								<li>To verify your transactions and for purchase confirmation, billing, security, and
								authentication. Always take security measures like not saving passwords in your browser
								or writing them down</li>
								<li>To analyze the Website or the other Services and information about our visitors and
								users, including research into our user demographics and user behavior in order to
								improve our content and Services</li>
								<li>To contact you about your account and provide customer service support, including
								responding to your comments and questions</li>
								<li>To share aggregate (non-identifiable) statistics about users of the Services to
								prospective partners</li>
								<li>To keep you informed about the Services, features, surveys, newsletters, offers and
								events we think you may find useful or which
								you have requested from us</li>
								<li>To better understand your needs and the needs of users in the aggregate, diagnose
								problems, analyze trends, improve the features and usability of the Services, and better
								understand and market to our customers and users</li>
								<li>To keep the Services safe and secure for everyone using the app from administrators to
								regular users with limited rights</li>
								<li>We also use non-identifiable information gathered for statistical purposes to keep track
								of the number of visits to the Services with a view to introducing improvements and
								improving usability of the Services.</li>
							</ol>
						</div>

						<div className="text-container">
							<h3>Customer Content We Process For Customers</h3>
							<p>BeamFire is a cloud-based software-as-a-service tool. Our Services enable
							our
							customers to
							enhance their products and services integrate with external software development tools that
							they already use, all in one place. Customer security is our primary focus in this document.
							</p>
						</div>

						<a className="btn-outline-reg"
							href="/">BACK</a>
					</div>
				</div>
			</div>
		</div>

		<div className="ex-basic-1">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="breadcrumbs">
							<a href="/">Home</a><i className="fa fa-angle-double-right" /><span>Privacy Policy</span>
						</div>
					</div>
				</div>
			</div>
		</div>

	</Layout>;

export default Page;
